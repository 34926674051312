import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Meta} from '@angular/platform-browser';
import {ApplicationService} from '../../core/services/application.service';
import {SwContact} from '../../core/models/site-entity/sw-contact';
import {LanguageService} from '../../core/services/language.service';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
    public lang: string;

    swContact: SwContact = new SwContact();
    succes: boolean;
    submit: boolean;
    checkoutForm: FormGroup;

    zoom: number;
    lat = 36.743883934354415;
    lng = 2.9471590207091167;
    capchaChecked = false;
    capchValidError: boolean;

    public center: { lat: number; lng: number };

    constructor(private route: ActivatedRoute,
                private metaTagService: Meta,
                public applicationService: ApplicationService,
                public languageService: LanguageService,
                private formBuilder: FormBuilder) {
        this.metaTagService.updateTag(
            {name: 'description', content: this.route.snapshot.data.description}
        );

    }

    ngOnInit() {
        /*init language*/
        this.lang = this.languageService.getLanguage();

        this.checkoutForm = this.formBuilder.group({
            swcnName: ['', Validators.required],
            swcnEmail: ['', Validators.required],
            swcnSubject: ['', Validators.required],
            swcnMessage: ['', Validators.required],
        });

        this.center = {
            lat: Number(this.lat),
            lng: Number(this.lng)
        };
        this.zoom = 15;
    }

    get form() {
        return this.checkoutForm.controls;
    }


    /**
     * content refresh
     */
    contentRefresh() {
    }


    appeler(tel: string) {
        return 'tel:+213' + tel;
    }

    sendMail(str: string) {
        return 'mailto:' + str;
    }

    /**
     * fetches the dashboard-2 data
     */

    onSubmit(): void {
        this.submit = true;

        if (this.submit && this.checkoutForm.valid && this.capchaChecked) {
            this.submit = false;
            this.capchValidError = false;
            this.applicationService.sendContactMail(this.swContact).subscribe(
                (resp) => {
                    this.succes = true;
                    this.applicationService.showSuccessMessage();
                },
                (err) => {
                    this.succes = false;
                    console.log(err);
                }
            );
            this.checkoutForm.reset();
        } else {
            this.capchValidError = true;
        }
    }


    // resolved(event) {
    //     this.messageService.add({
    //         severity: 'info', summary: 'Succees', detail: 'L\'utilisateur a répondu\n' +
    //             '\n', sticky: true
    //     });
    //     this.capchaChecked = true;
    //     this.capchValidError = false;
    // }


}
