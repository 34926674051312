<div class="one-header-top two-header-top "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-2">
                <div class="header-social d-block">
                    <ul>
                        <li><a target="_blank" href="https://m.facebook.com/blink.solutions.algerie/"><i
                            class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank"
                               href="https://dz.linkedin.com/company/b-link-solution"><i
                            class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>{{'HEADER.INSTANT_QUOTES_BEST_COVERAGE' | translate}} <a class="cursor-pointer"
                                                                                (click)="applicationService.goToLink('https://ameni.b-link.io/ec/#/')">{{'HEADER.GET_INSURANCE_TODAY' | translate}}</a>
                    </p>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-call d-flex align-items-center justify-content-between">
                    <ul class="" [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
                        <li>
                            <span>{{'HEADER.CONTACT_US_LINK' | translate}}</span>
                            <a class=""
                               [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'"
                               href="tel:+213 770 92 78 30">{{'LANDING.CONTACT_PHONE' |translate}}</a>
                        </li>
                        <li>
                            <span>{{'HEADER.EMAIL_US_LINK' | translate}}</span>
                            <a href="mailto:{{'LANDING.CONTACT_PHONE' |translate}}">{{'LANDING.CONTACT_EMAIL' |translate}}</a>
                        </li>
                    </ul>
                    <div class="header-language">
                        <ul>
                            <li (click)="setLanguage('fr')"><img src="assets/img/flags/fr.png" alt="fr"
                                                                 style="width: 20px"></li>
                            <li (click)="setLanguage('en')"><img src="assets/img/flags/en.png" alt="en"
                                                                 style="width: 20px"></li>
                            <li (click)="setLanguage('ar')"><img src="assets/img/flags/ar.png" alt="ar"
                                                                 style="width: 20px"></li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light navbar-light-two sticky-top "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <a class="navbar-brand" (click)="applicationService.goToLink('#/')"><img src="assets/img/logo.png"
                                                                             style="cursor: pointer ;width: 180px;"
                                                                             alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <ul class="navbar-nav" [ngClass]="{'direction-ltr': applicationService.isMobile()}">


            <li class="nav-item cursor-pointer" (click)="applicationService.goToLink('#')">
                <a class="nav-link ">{{'HEADER.HOME' | translate}}</a></li>

            <li class="nav-item cursor-pointer">
                <a class="nav-link" href="#/about"> {{'HEADER.ABOUT_US' | translate}}</a>
            </li>

            <li class="nav-item cursor-pointer"><a class="nav-link" href="#">{{'HEADER.OUR_SERVICES' | translate}}</a>
            </li>

            <li class="nav-item cursor-pointer">
                <a class="nav-link" href="#/blogs">
                    {{'HEADER.NEWS_AND_BLOG' | translate}}</a>
            </li>

            <li class="nav-item cursor-pointer"><a class="nav-link" href="#contact">
                {{'HEADER.CONTACT_US' | translate}}</a>
            </li>

            <div class="dropdown d-inline-block" >
                <button type="button" class="btn header-item" id="page-header-user-dropdown">
                    <div class="d-flex align-items-center justify-content-between">
                   <span><img src="{{selectedLanguage.flag}}"
                              width="22px"></span> <span class="ml-2 mr-2">{{selectedLanguage.text}}</span>
                    </div>
                </button>
            </div>
            <div *ngIf="applicationService.isMobile()" class="mobile-sign-in d-none d-sm-block"
                 (click)="applicationService.goToLink('https://ameni.b-link.io/ec/')"><a><i
                class="bx bx-link-external "></i>{{'HEADER.EMAIL_US_LINK' | translate}}</a></div>

        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-right cd-signup" (click)="applicationService.goToLink('https://ameni.b-link.io/ec/#/')">
            <i class='bx bx-link-external'></i>{{'HEADER.LOGIN' | translate}}</a>
    </div>
</nav>
