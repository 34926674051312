<div id="about" class="three-about-area ">
    <div class="container " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content ">
                    <div class="one-section-title two-section-title "
                         [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
                        <span class="sub-title "
                              [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">{{languageService.getLanguage() === 'en' ? selectedPrdArgs.titleEn : languageService.getLanguage() === 'fr'
                            ? selectedPrdArgs.titleFr : languageService.getLanguage() === 'ar' ? selectedPrdArgs.titleAr : selectedPrdArgs.titleEn}}</span>
                        <h2>{{languageService.getLanguage() === 'en' ? selectedPrdArgs.titleDetailsEn : languageService.getLanguage() === 'fr'
                            ? selectedPrdArgs.titleDetailsFr : languageService.getLanguage() === 'ar' ? selectedPrdArgs.titleDetailsAr : selectedPrdArgs.titleDetailsEn}}</h2>
                    </div>

                    <div class="about-mission">
                        {{languageService.getLanguage() === 'en' ? selectedPrdArgs.descriptionEn : languageService.getLanguage() === 'fr'
                        ? selectedPrdArgs.descriptionFr : languageService.getLanguage() === 'ar' ? selectedPrdArgs.descriptionAr : selectedPrdArgs.descriptionEn}}
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-img">
                    <img [attr.src]="selectedPrdArgs.image" alt="About">
                    <div class="about-inner" *ngIf="promotion">
                        <h3>{{promotion.swpTitreFrn}}</h3>
                        <div [innerHtml]="promotion.swpDescFrn"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="buying-home-area pt-2 pb-2 "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid direction-ltr col-lg-9 col-sm-12">
        <app-guarantees [listGanrties]="guaranteesList"></app-guarantees>
    </div>

    <div class="container-fluid direction-ltr pt-5 col-12">
        <app-devis [prodcut]="selectedProduct" [prodcutaArgs]="selectedPrdArgs"></app-devis>
    </div>

    <app-menu></app-menu>
</div>
