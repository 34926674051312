<div id="home" class="banner-buy-area "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="banner-buy-content">
            <h1>{{'NEWS_AND_BLOG.TEXT'|translate}}</h1>
            <p>{{languageService.getLanguage()==='en'? selectedBlog.sblTitreFrn :
                languageService.getLanguage()==='fr'? selectedBlog.sblTitreLoc : selectedBlog.sblTitreArb }}</p>
        </div>

    </div>
</div>
<div class="buying-home-area pt-2 pb-2">
    <div class="container-fluid " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
        <article class="hentry">

            <div class="featured-image">
                <div class="buying-home-image">
                    <img src="assets/img/blog-1.jpg" alt="image">
                </div>

            </div>
            <h1 class="entry-title">{{selectedBlog.sblTitreFrn}}</h1>
            <div class="entry-meta">
                <p><span class="author"><span>{{'SITE_NAME' | translate}}</span></span> <span
                    class="date">{{selectedBlog.sblCreationDate | date :'MMM d, y, h:mm:ss a'}}</span>
                </p>
            </div>
            <div class="entry-content" [innerHTML]="languageService.getLanguage()==='en'? selectedBlog.sblDescFrn :
              languageService.getLanguage()==='fr'? selectedBlog.sblDescLoc : selectedBlog.sblDescArb ">
            </div>
        </article>
    </div>

</div>
