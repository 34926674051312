<div id="home" class="banner-buy-area " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="banner-buy-content">
            <h1>وكالاتنا</h1>
            <p></p>
        </div>
    </div>
</div>
<div class="container " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">

    <div class="main-section-title">
        <div class="col-lg-12 col-md-3"
             [ngClass]="{'d-flex': applicationService.isMobile(), 'flex-column' : applicationService.isMobile()}">
            <!--<p-dropdown [options]="listCfWilayas" [(ngModel)]="cfWilayas" (onChange)="onSelectWilayas()"-->
                        <!--filter="true"-->
                        <!--placeholder="{{ config.findConfig( 'nos_agences_choisier_une_wilaya', config.initLanguage())}}"-->
                        <!--optionLabel="wilNomLoc" class="ml-3 mb-3">-->
                <!--<ng-template pTemplate="selectedItem">-->
                    <!--<div class="country-item country-item-value">-->
                        <!--<div>{{cfWilayas.wilNomLoc}}</div>-->
                    <!--</div>-->
                <!--</ng-template>-->

            <!--</p-dropdown>-->

            <!--<p-dropdown [options]="listcfVilles" [(ngModel)]="cfVilles" (onChange)="onSelectVille()"-->
                        <!--[filter]="true" [showClear]="true"-->
                        <!--placeholder="{{ config.findConfig( 'nos_agences_choisier_une_ville', config.initLanguage())}}"-->
                        <!--optionLabel="vilNomLoc" class="ml-3 mb-3">-->
                <!--<ng-template pTemplate="selectedItem">-->
                    <!--<div class="country-item country-item-value">-->
                        <!--<div>{{cfVilles.vilNomLoc}}</div>-->
                    <!--</div>-->
                <!--</ng-template>-->

            <!--</p-dropdown>-->

            <!--<p-dropdown [options]="listcfVilles" [(ngModel)]="stAgences" (onChange)="onSelectAgence()"-->
                        <!--[filter]="true" [showClear]="true"-->
                        <!--placeholder="{{ config.findConfig( 'nos_agences_choisier_une_agence', config.initLanguage())}}"-->
                        <!--optionLabel="agnNomLoc" class="ml-3 mb-3">-->
                <!--<ng-template pTemplate="selectedItem">-->
                    <!--<div class="country-item country-item-value">-->
                        <!--<div>{{stAgences.agnNomLoc}}</div>-->
                    <!--</div>-->
                <!--</ng-template>-->

            <!--</p-dropdown>-->
        </div>

        <div class="let-contact-map pt-5" style="padding-left: 0px !important">
            <div class="col-lg-12 bg-color mt-3" >

                <agm-map width="100%" height="450px" [zoom]="zoom" [latitude]="lat" [longitude]="lng"  >
                    <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="markerUrl" >
                    </agm-marker>
                </agm-map>

            </div>
        </div>
    </div>
    <app-menu></app-menu>
</div>



