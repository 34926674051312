import {BACKEND_API} from '../core/helpers/api.url';

export const  PAGES_URLS = {


    FIND_ST_FIND_ACTUALITE: BACKEND_API + 'blgApi/findBlogsActualite',
    FIND_ST_FIND_TOP_BLOGS: BACKEND_API + 'blgApi/findTopBlogs',
    FIND_ST_FIND_BLG_BY_PATH: BACKEND_API + 'blgApi/findBySblPath',
    FIND_ST_FIND_ALL_BLOGS: BACKEND_API + 'blgApi/findAllBlogs',
    FIND_ST_FIND_ALL_AGENCES_BY_WILAYA: BACKEND_API + 'agnApi/findAgencesByWilayas',
    FIND_ST_FIND_ALL_AGENCES_BY_VILLE: BACKEND_API + 'agnApi/findAgencesByVilles',
    FIND_ST_FIND_MAX_UPDATED_CONFIG: BACKEND_API + 'cfgApi/findAMaxUpdatedConfig',
    SEARCH: BACKEND_API + 'result',
    FIND_ST_FIND_ALL_FILES: BACKEND_API + 'filesApi/findAllFiles',
    FIND_ST_FIND_AVIS_AGENCE_BY_AGENCE: BACKEND_API + 'agaApi/findAvisByAgence',
    FIND_ST_FIND_COMMENTAIRES: BACKEND_API + 'cmtApi/findAllCommentairesByProduct',
    FIND_ST_NBR_COMMENTAIRES: BACKEND_API + 'cmtApi/nbrCommentaires',
    FIND_ST_FIND_TOP_PRODUCTS: BACKEND_API + 'prdApi/findTopProducts',
    FIND_All_PRODUCTS: BACKEND_API + 'prdApi/findAll',
    FIND_ST_FIND_TOP_ALL_PRODUCTS: BACKEND_API + 'prdApi/findAllTopProducts',
    FIND_PRODUCT: BACKEND_API + 'prdApi/findBySprId',


    POST_ST_CREATE_COMMENTAIRES: BACKEND_API + 'cmtApi/createCommentaire',
    COMENTAIRES_API: BACKEND_API + 'cmtApi',
    POST_ST_CREATE_REP_COMMENTAIRES: BACKEND_API + 'repCmtApi/createRpCommentaire',
    POST_ST_CREATE_AGENCE_AVIS: BACKEND_API + 'agaApi/createAgenceAvis',
    CONFIG_API: BACKEND_API + 'cfgApi',
    FIND_ST_FIND_CONFIG_BY_NAME: BACKEND_API + 'cfgApi/findConfigByName',
    FILES_API: BACKEND_API + 'filesApi',
    PRD_API: BACKEND_API + 'prdApi',
    BLG_API: BACKEND_API + 'blgApi',
    GAR_API: BACKEND_API + 'garsApi',
    FIND_GAR_API: BACKEND_API + 'garsApi/findGaranties',
    FIND_GAR_BY_ID_API: BACKEND_API + 'garsApi/findGarantieById',


    ALL_PROMOTIONS_API: BACKEND_API + 'promApi/findAllPromotions',



};
