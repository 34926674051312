<div id="home" class="banner-buy-area " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="banner-buy-content">
            <h1>{{'ABOUT.TEXT' | translate}}</h1>
        </div>

    </div>
</div>

<div id="about" class="about-style-area ptb-100 ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image">
                    <img src="assets/img/about.jpg" alt="image">

                    <div class="quote-content">
                        <p>{{'ABOUT.CEO_WORD' | translate}}</p>
                        <div class="d-flex flex-column">
                            <span>{{'ABOUT.CEO_NAME' | translate}}</span>
                            <span style="font-size: x-small">{{'ABOUT.CEO_TITLE' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
                <div class="about-style-content">
                    <span class="sub-title">{{'ABOUT.DESCRIPTION' | translate}}</span>
                    <h3>{{'ABOUT.TITLE' | translate}}</h3>
                    <p>{{'ABOUT.DESCRIPTION' | translate}}</p>

                    <div class="row justify-content-center">
<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="counter-box">-->
<!--                                <h4>20+</h4>-->
<!--                                <span>لوريم إيبسوم</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-lg-6 col-sm-6">-->
<!--                            <div class="counter-box">-->
<!--                                <h4>90%</h4>-->
<!--                                <span>لوريم إيبسوم</span>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

