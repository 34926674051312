<footer class="footer-style-area pt-100 " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-two.png" alt="Logo">
                        </a>
                        <p>{{'SITE_NAME'| translate}}</p>
                        <ul class="footer-social">
                            <li>
                                <a href="https://m.facebook.com/blink.solutions.algerie/" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://dz.linkedin.com/company/b-link-solution" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <!--<li>-->
                            <!--<a href="#" target="_blank">-->
                            <!--<i class='bx bxl-instagram'></i>-->
                            <!--</a>-->
                            <!--</li>-->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>{{'FOOTER.QUICK_LINKS'|translate}}</h3>

                    <ul  [ngClass]="languageService.getLanguage()==='ar'? 'quick-link' : 'quick-link-ltr'" >
                        <li>
                            <a href="#/about">{{'FOOTER.ABOUT_US'|translate}}</a>
                        </li>
                        <li>
                            <a href="#">{{'FOOTER.OUR_SERVICES'|translate}}</a>
                        </li>
                        <li>
                            <a href="#about-our-company">{{'FOOTER.ABOUT_OUR_COMPANY'|translate}}</a>
                        </li>
                        <li>
                            <a href="#/contact">{{'FOOTER.CONTACT_US'|translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>{{'FOOTER.COMPANY.TEXT'|translate}}</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="#/blogs">{{'FOOTER.COMPANY.NEWS_AND_BLOG'|translate}}</a>
                        </li>
                        <li>
                            <a href="#FaqAccordion">{{'FOOTER.COMPANY.FAQS'|translate}}</a>
                        </li>
                        <li>
                            <a href="#typeProducts">{{'FOOTER.COMPANY.TYPES_OF_OFFERS'|translate}}</a>
                        </li>
                        <li>
                            <a href="#/nos-agences">{{'FOOTER.COMPANY.OUR_AGENCIES'|translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>{{'FOOTER.SUPPORT'|translate}}</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="#">{{'SUPPORT.CONTACT_US'|translate}} </a>
                        </li>
                        <li>
                            <a href="#">{{'SUPPORT.PRIVACY_POLICY'|translate}}</a>
                        </li>
                        <li>
                            <a href="#">{{'SUPPORT.HOME'|translate}}</a>
                        </li>
                        <li>
                            <a href="#">{{'SUPPORT.TERMS_AND_CONDITIONS'|translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>{{'FOOTER.ADDRESS'|translate}}</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:{{'LANDING.CONTACT_EMAIL' |translate }}">{{'LANDING.CONTACT_PHONE' |translate }} </a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:{{'LANDING.CONTACT_EMAIL' |translate }}">{{'LANDING.CONTACT_EMAIL' |translate }}</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                          {{'LANDING.ADDRESS_VALUE'|translate}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item m-lg-5">
                        <p>© {{'SITE_NAME' |translate}} {{'POWERED_BY' |translate}} <a href="https://b-link.io/"
                                                                                       target="_blank">{{'COMPANY_NAME' |translate}}</a>
                        </p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <ul class="payment-list">
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/cib.png" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/poste.png" alt="Method">
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
