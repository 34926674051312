<div class="faq-style-area" [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="main-section-title">
            <h2>{{'GUARANTEES' | translate}}</h2>
        </div>

        <div class="faq-style-accordion">
            <div class="accordion" id="FaqAccordion">
                <div class="accordion-item" *ngFor="let grt of listGanrties ;let i=index; first as isFirst">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse' + i"
                            aria-expanded="true" [attr.aria-controls]="'collapse' +i">
                        {{languageService.getLanguage() === 'en' ? grt.swgNomFrn : languageService.getLanguage() === 'fr'
                        ? grt.swgNomLoc : languageService.getLanguage() === 'ar' ? grt.swgNomArb : grt.swgValueFrn}}
                    </button>
                    <div id="collapse{{i}}" class="accordion-collapse collapse " [class.show]="isFirst"
                         data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <div [innerHTML]="languageService.getLanguage() === 'en' ? grt.swgValueFrn : languageService.getLanguage() === 'fr'
                        ? grt.swgValueLoc : languageService.getLanguage() === 'ar' ? grt.swgValueArb : grt.swgValueFrn"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

