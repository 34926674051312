import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {RouterLink} from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {ChatComponent} from './chat-component/chat.component';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ChatComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        ChatComponent
    ],
    imports: [
        CommonModule,
        RouterLink,
        TranslateModule,
        FormsModule
    ]
})
export class SharedModule {
}
