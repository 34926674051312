import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesRoutingModule} from './pages-routing.module';
import {AboutComponent} from './about/about.component';
import {BlogComponent} from './blog/blog.component';
import {BlogsComponent} from './blogs/blogs.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {DevisComponent} from './devis/devis.component';
import {GuaranteesComponent} from './guarantees/guarantees.component';
import {LandingComponent} from './landing/landing.component';
import {MenuComponent} from './menu/menu.component';
import {OurAgenciesComponent} from './our-agencies/our-agencies.component';
import {ProductComponent} from './product/product.component';
import {ContactComponent} from './contact/contact.component';
import {TranslateModule} from '@ngx-translate/core';
import {AgmCoreModule} from '@agm/core';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';


@NgModule({
    declarations: [AboutComponent, BlogComponent, BlogsComponent, PageNotFoundComponent,
        ContactFormComponent, DevisComponent, GuaranteesComponent, ContactComponent,
        LandingComponent, MenuComponent, OurAgenciesComponent, ProductComponent],
    imports: [
        CommonModule,
        PagesRoutingModule,
        TranslateModule,
        AgmCoreModule,
    ],
    exports: [
        AboutComponent, BlogComponent, BlogsComponent, PageNotFoundComponent,
        ContactFormComponent, DevisComponent, GuaranteesComponent, ContactComponent,
        LandingComponent, MenuComponent, OurAgenciesComponent, ProductComponent
    ]
})
export class PagesModule {
}
