import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../core/services/language.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    public lang: string;

    constructor(public languageService: LanguageService) {
    }

    ngOnInit() {
        this.lang = this.languageService.getLanguage();
    }

}
