<div id="home" class="banner-buy-area " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="banner-buy-content">
            <h1>{{'CONTACT_FORM.TITLE' | translate}}</h1>
            <p></p>
        </div>

    </div>
</div>
<div  class="container-fluid">
<app-contact-form></app-contact-form>
</div>
<app-menu></app-menu>

