import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {SwBlogs} from '../../models/site-entity/sw-blogs';

@Injectable({
    providedIn: 'root'
})
export class SwBlogsService {
    constructor(private http: HttpClient) {
    }

    findAllBlogs(): Observable<SwBlogs[]> {
        return this.http.get<SwBlogs[]>(PAGES_URLS.FIND_ST_FIND_ALL_BLOGS,
        );
    }

    findBlogByPath(params: HttpParams): Observable<SwBlogs> {
        return this.http.get<SwBlogs>(PAGES_URLS.FIND_ST_FIND_BLG_BY_PATH, {params}
        );
    }

    findTopBlogs(): Observable<SwBlogs[]> {
        return this.http.get<SwBlogs[]>(PAGES_URLS.FIND_ST_FIND_TOP_BLOGS,
        );
    }
}
