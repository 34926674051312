import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SwBlogsService} from '../../core/services/site/swBlogs.service';
import {Router} from '@angular/router';
import {ApplicationService} from '../../core/services/application.service';
import {SwBlogs} from '../../core/models/site-entity/sw-blogs';
import {LanguageService} from '../../core/services/language.service';
import {Product} from '../product/product.model';
import {PRODUCTS_LIST} from '../product/product.data';


@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    providers: [ApplicationService],
    encapsulation: ViewEncapsulation.None,
})

export class LandingComponent implements OnInit {

    constructor(private http: HttpClient,
                private swBlogsService: SwBlogsService,
                public languageService: LanguageService,
                public applicationService: ApplicationService,
                private router: Router) {

    }

    public lang: string;

    listAllBlogs: SwBlogs[] = [];
    newestBlog: SwBlogs;

    listProducts: Array<Product> = [];

    ngOnInit(): void {
        this.findAllBlogs();
        this.findAllProducts();
        this.lang = this.languageService.getLanguage();
    }

    findAllProducts() {
        this.listProducts = PRODUCTS_LIST;
    }

    public findAllBlogs() {
        this.newestBlog = new SwBlogs();
        this.swBlogsService.findTopBlogs().subscribe(
            data => {
                this.listAllBlogs = data;
                this.newestBlog = this.listAllBlogs[0];

            }, error => {
                if (error.status === '403') {
                    this.router.navigate(['/auth/login']);
                }
            },
        );

    }


}
