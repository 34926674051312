import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationService} from '../../core/services/application.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SwProduitsService} from '../../core/services/site/swProduits.service';
import {SwPromotionsService} from '../../core/services/site/swPromotions.service';
import {PRODUCTS_LIST} from './product.data';
import {SwProduits} from '../../core/models/site-entity/sw-produits';
import {Product} from './product.model';
import {SwPromotions} from '../../core/models/site-entity/sw-promotions';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../../core/services/language.service';
import {SwGaranties} from '../../core/models/site-entity/sw-garantie';


@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    public lang: string;

    selectedProduct = new SwProduits();
    productId: string;
    productArgs: Array<Product>;
    selectedPrdArgs: Product;

    promotion = new SwPromotions();
    promostionsList: SwPromotions[];
    guaranteesList: SwGaranties[] = [];


    constructor(private http: HttpClient,
                public applicationService: ApplicationService,
                private router: Router,
                private route: ActivatedRoute,
                private cookiesService: CookieService,
                public languageService: LanguageService,
                private swProduitsService: SwProduitsService,
                private swPromotionsSerivce: SwPromotionsService) {
    }

    ngOnInit() {
        this.lang = this.cookiesService.get('lang');
        this.productArgs = [];
        this.promostionsList = [];
        this.route.params.subscribe(
            (params: Params) => {
                this.productId = atob(params.id);
                this.findProductById(this.productId);
                this.productArgs = PRODUCTS_LIST;
                this.selectedPrdArgs = this.productArgs.filter(c => Number(atob(c.prdId)) === Number(this.productId))[0];
                this.promostionsList = this.selectedProduct.swPromotionsList;
                this.guaranteesList = this.selectedProduct.swGarantiesList;
            }
        );

    }


    private findProductById(sprId: string) {
        this.swProduitsService.findProducts(sprId).subscribe(
            data => {
                this.selectedProduct = data;
                this.promotion = this.selectedProduct.swPromotionsList[0];
                this.guaranteesList = this.selectedProduct.swGarantiesList;
            },
            error => console.log(error)
        );

    }

    private findAllPromotions() {
        this.swPromotionsSerivce.findAll().subscribe(data => {
                this.promostionsList = data;
            },
            error => console.log(error));
    }


}
