import { Component, OnInit } from '@angular/core';
import {ChatService, Message} from '../../../core/services/site/chat-service.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    messages: Message[] = [];
    value: string;

    notifFlag = true;
    constructor(public chatService: ChatService) { }
    ngOnInit() {
        this.value = 'مرحبا';
        this.chatService.conversation.subscribe((val) => {
            this.messages = this.messages.concat(val);
        });
    }
    sendMessage() {
        this.chatService.getBotAnswer(this.value);
        this.value = '';
    }

    openConversation() {
        this.notifFlag = false;
    }

}
