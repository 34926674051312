import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApplicationService} from '../../core/services/application.service';
import {LanguageService} from '../../core/services/language.service';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    providers: [ApplicationService]
})
export class ContactComponent implements OnInit {

    public lang: string;

    constructor(private http: HttpClient,
                public applicationService: ApplicationService,
                public languageService: LanguageService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.lang = this.languageService.getLanguage();
    }

}
