import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {PagesModule} from './pages/pages.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {ChatService} from './core/services/site/chat-service.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AgmCoreModule} from '@agm/core';


export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        LayoutModule,
        PagesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({apiKey: 'AIzaSyDCHPq6O--jPR1o74z66_8GvzJnYO_i85Y'})
    ],
    providers: [MessageService, ChatService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
