<div id="home" class="banner-buy-area "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="banner-buy-content">
            <h1>{{'NEWS_AND_BLOG.TEXT'|translate}}</h1>
        </div>

    </div>
</div>
<div id="blog" class="one-blog-area two-blog-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">{{'NEWS_AND_BLOG.TEXT'|translate}}</span>
            <h2>{{'NEWS_AND_BLOG.NEWS_AND_FEATURED_STATS'|translate}}</h2>
        </div>

        <main>
            <div class="row " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
                <div class="col-sm-6 col-lg-4" *ngFor="let blog of listAllBlogs; index as i; first as isFirst">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a routerLink="/"><img src="assets/img/blog-1.jpg" alt="Blog"></a>

                            <span><img src="assets/img/admin.png" style="width: 40px">  {{'SITE_NAME' | translate}}</span>
                        </div>

                        <div class="blog-bottom">
                            <ul>
                                <li>
                                    <i class='bx bx-time-five'></i>
                                    {{blog.sblCreationDate | date :'MMM d, y'}}
                                </li>
                                <li>
                                    <i class='bx bx-pin'></i>
                                    {{blog.sblAdresse}}
                                </li>
                            </ul>
                            <h3 class="cursor-pointer"><a (click)="applicationService.goToLink('#/blogs/'+blog.sblPath)">{{blog.sblTitreFrn}}</a></h3>
                            <p><a (click)="applicationService.goToLink('#/blogs/'+blog.sblPath)">{{'SITE_NAME' | translate}}</a>, Author</p>
                            <div class="entry-content mt-lg-4 blog-card-desc" [innerHTML]="blog.sblDescLoc"></div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
