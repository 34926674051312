import {Component, Input, OnInit} from '@angular/core';
import {ApplicationService} from '../../core/services/application.service';
import {SwProduits} from '../../core/models/site-entity/sw-produits';
import {Product} from '../product/product.model';
import {LanguageService} from '../../core/services/language.service';

@Component({
    selector: 'app-devis',
    templateUrl: './devis.component.html',
    styleUrls: ['./devis.component.scss']
})
export class DevisComponent implements OnInit {
    public lang: string;

    @Input() prodcut: SwProduits;
    @Input() prodcutaArgs: Product;

    constructor(public applicationService: ApplicationService,
                public languageService: LanguageService) {
    }

    ngOnInit() {
        this.lang = this.languageService.getLanguage();
    }

}
