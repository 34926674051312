<div class="mortgage-quote-area  " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="mortgage-quote-content">
                    <h3>{{'INSURANCE.QUOTE_REQUEST' |translate}}</h3>
                    <p>{{'INSURANCE.SEARCHING_FOR_POLICY' |translate}}</p>
                    <a class="quote-btn cursor-pointer"
                       (click)="applicationService.goToLink(prodcut.sprPath)">{{'INSURANCE.REQUEST_QUOTE' |translate}}</a>
                </div>
            </div>
            <div class="col-lg-6 p-0">
                <div
                    style=" height: 97% !important; border-top-left-radius: 0 !important;border-bottom-left-radius: 1.1em !important;border-top-right-radius: 0 !important;"
                    class="mortgage-quote-image"
                    [ngStyle]="{'background-image': 'url(' + prodcutaArgs.imageSous + ')'}"></div>
            </div>
        </div>
    </div>
</div>
