import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationService} from '../application.service';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {SwProduits} from '../../models/site-entity/sw-produits';

@Injectable({
    providedIn: 'root'
})


export class SwProduitsService {

    constructor(private http: HttpClient,
                public applicationService: ApplicationService) {
    }

    findAllProducts(): Observable<SwProduits[]> {
        return this.http.get<SwProduits[]>(PAGES_URLS.FIND_ST_FIND_TOP_ALL_PRODUCTS
        );
    }

    findProducts(sprId: string): Observable<SwProduits> {
        const params = new HttpParams()
            .set('sprId', sprId);
        return this.http.get<SwProduits>(PAGES_URLS.FIND_PRODUCT, {
                params
            }
        );
    }

}
