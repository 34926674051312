<div id="contact" class="let-contact-area with-main-color pt-5">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="let-contact-form " [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
                    <span class="sub-title">{{'CONTACT_FORM.TITLE' |translate}}</span>
                    <h3>{{'CONTACT_FORM.LEAVE_MESSAGE' |translate}}</h3>

                    <form id="contactForm">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{'CONTACT_FORM.NAME' |translate}}</label>
                                    <input type="text" name="name" id="name" class="form-control" required
                                           data-error="Please enter your name" placeholder="ameni">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{'CONTACT_FORM.EMAIL' |translate}}</label>
                                    <input type="email" name="email" id="email" class="form-control" required
                                           data-error="Please enter your email" placeholder="example@mail.com">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{'CONTACT_FORM.PHONE_NUMBER' |translate}}</label>
                                    <input type="text" name="phone_number" id="phone_number" required
                                           data-error="Please enter your number" class="form-control"
                                           placeholder="0770 92 78 30">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{'CONTACT_FORM.SUBJECT' |translate}}</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                           data-error="Please enter your subject"
                                           placeholder="{{'CONTACT_FORM.YOUR_SUBJECT' |translate}}">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label>{{'CONTACT_FORM.MESSAGE' |translate}}</label>
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                              required data-error="Write your message"
                                              placeholder="{{'CONTACT_FORM.WRITE_MESSAGE' |translate}}"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
<!--                            <div class="col-md-12 col-lg-12 pb-2">-->
<!--                                <re-captcha name="swcnMessage" (resolved)="resolved($event)"-->
<!--                                            siteKey="6Lefpm8jAAAAAF8O2ulbsiNatYYF6nfaIiED0Bzn" required></re-captcha>-->
<!--                                <div *ngIf="capchValidError" class="alert-danger">-->
<!--                                    <span *ngIf="capchValidError">Ce champ est obligatoire</span>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-md-12 col-lg-12">
                                <div class="let-contact-btn">
                                    <button type="submit"
                                            class="main-default-btn">{{'CONTACT_FORM.SEND' |translate}}</button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="let-contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25579.726248963805!2d2.934541910128915!3d36.73538942496945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128faf79ad724b09%3A0x48420dccc308e9c4!2sBlink%20solution!5e0!3m2!1sfr!2sdz!4v1700661814108!5m2!1sfr!2sdz"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="let-contact-shape">
        <img src="assets/img/more-home/let-contact-shape.png" alt="image">
    </div>
</div>
