import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';


@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private languages: string[] = ['en', 'fr', 'ar'];
    public lang: string;

    constructor(public translate: TranslateService, private cookieService: CookieService) {
        let browserLang;
        this.translate.addLangs(this.languages);
        if (this.cookieService.check('lang')) {
            browserLang = this.cookieService.get('lang');
        } else {
            browserLang = translate.getBrowserLang();
        }
        translate.use(browserLang.match(/en|fe|ar/) ? browserLang : 'fr');
        this.lang = this.cookieService.get('lang');
    }

    public setLanguage(lang) {
        this.translate.use(lang);
        this.cookieService.set('lang', lang);
    }

    public getLanguage(): string {
        return this.cookieService.get('lang');
    }

}
