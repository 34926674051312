import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

import {AppSettings} from '../models/app-settings';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {API_URLS} from '../helpers/api.url';
import {SwContact} from '../models/site-entity/sw-contact';

@Injectable({providedIn: 'root'})
export class ApplicationService {

    constructor(private httpClient: HttpClient,
                private messageService: MessageService,
                private router: Router) {
    }

    get refreshData() {
        return this._refreshData;
    }

    get appSetting(): AppSettings {
        return this._appSetting;
    }

    token: any;
    isDevloper: boolean;

    lang: BehaviorSubject<string> = new BehaviorSubject<string>('fr');

    // tslint:disable-next-line:variable-name
    private _appSetting: AppSettings;
    // tslint:disable-next-line:variable-name
    private _refreshData = new Subject<void>();

    setLoggedIn(lang: string): void {
        return this.lang.next(lang);
    }

    switchLanguage(language: string) {
        this.lang.next(language);
    }

    isMobile() {
        const ua = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
    }

    /*Local services */
    public saveLocalData(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public getLocalData(key: string) {
        return JSON.parse(localStorage.getItem(key));
    }

    public getLocalDataX(key: string) {
        return localStorage.getItem(key);
    }


    public removeLocalData(key: string) {
        localStorage.removeItem(key);
    }

    public clearLocalData() {
        localStorage.clear();
    }

    checkLocalData(key: string) {
        if (key in localStorage) {
            return true;
        } else {
            return false;
        }
    }


    downloadMyFile(fileName: string, filePath: string) {
        setTimeout(() => {
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', filePath);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }, 250);
    }


    showSuccess() {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Message Content'});
    }

    showInfo() {
        this.messageService.add({severity: 'info', summary: 'Info', detail: 'Message Content'});
    }

    showWarn() {
        this.messageService.add({severity: 'warn', summary: 'Warn', detail: 'Message Content'});
    }

    showError() {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Message Content'});
    }

    showSuccessMessage() {
        this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Message Envoyer  avec succès'
        });
        // this.showConfDialog = false;
    }

    private tokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }


    getMoisByPeriode(month: string) {

        let monthString = '';

        const y: number = +(month.charAt(5) + month.charAt(6));
        switch (y) {
            case 1:
                monthString = 'Janvier';
                break;
            case 2:
                monthString = 'Février';
                break;
            case 3:
                monthString = 'Mars';
                break;
            case 4:
                monthString = 'Avril';
                break;
            case 5:
                monthString = 'Mai';
                break;
            case 6:
                monthString = 'Juin';
                break;
            case 7:
                monthString = 'Juillet';
                break;
            case 8:
                monthString = 'Aout';
                break;
            case 9:
                monthString = 'Septembre';
                break;
            case 10:
                monthString = 'Octobre';
                break;
            case 11:
                monthString = 'Novembre';
                break;
            case 12:
                monthString = 'Décembre';
                break;
            default:
                monthString = 'Invalid month';
                break;
        }

        return monthString;
    }


    sendContactMail(swcontact: SwContact) {
        return this.httpClient.post(API_URLS.SEND_CONATCT_MAIL, swcontact);
    }

    scrollTo(el: HTMLElement) {
        el.scrollIntoView({behavior: 'smooth'});
    }


    getBoolean(value) {
        switch (value) {
            case true:
            case 'true':
            case 1:
            case '1':
            case 'on':
            case 'yes':
                return true;
            default:
                return false;
        }
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    youtube_parser(url) {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }

    goToLink(link: string) {
        window.location.href = link;
    }

    goToDetails(path: string, arg: any) {
        this.router.navigate([path, arg]);
    }

    public jasonParser(data: any) {
        return JSON.parse(JSON.stringify(data));
    }

}
