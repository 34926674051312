import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SwGarantiesService} from '../../core/services/site/swGarantie.service';
import {ApplicationService} from '../../core/services/application.service';
import {HttpParams} from '@angular/common/http';
import {SwGaranties} from '../../core/models/site-entity/sw-garantie';
import {LanguageService} from '../../core/services/language.service';

@Component({
    selector: 'app-guarantees',
    templateUrl: './guarantees.component.html',
    styleUrls: ['./guarantees.component.scss']
})
export class GuaranteesComponent implements OnInit {

    @Input() listGanrties: SwGaranties[];

    constructor(public applicationService: ApplicationService,
                public languageService: LanguageService) {
    }

    ngOnInit() {
        // this.findGarntiesByProducts(this.swgSprId);
    }

    // private findGarntiesByProducts(swgSprId: string) {
    //     const params = new HttpParams()
    //         .set('swgSprId', swgSprId);
    //
    //     this.swGarntiesService.findGarantiesByProduit(params).subscribe(
    //         data => {
    //             // tslint:disable-next-line:triple-equals
    //             this.listGanrties = data;
    //
    //         },
    //         error => console.log(error));
    // }

}
