import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreloaderComponent} from './preloader/preloader.component';
import {LayoutComponent} from './layout/layout.component';
import {RouterOutlet} from '@angular/router';
import {SharedModule} from './shared/shared.module';


@NgModule({
    declarations: [PreloaderComponent, LayoutComponent],
    exports: [
        PreloaderComponent, LayoutComponent
    ],
    imports: [
        CommonModule,
        RouterOutlet,
        SharedModule,
    ]
})
export class LayoutModule {
}
