import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {SwPromotions} from '../../models/site-entity/sw-promotions';

@Injectable({
  providedIn: 'root',
})
export class SwPromotionsService {

  constructor(private http: HttpClient) {
  }

  findAll() {
    return this.http.get<SwPromotions[]>(
        PAGES_URLS.ALL_PROMOTIONS_API
      );
  }


}
