import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
    loading = true;

    constructor() {
    }

    ngOnInit(): void {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 1000);

    }

}
