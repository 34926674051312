<div id='oopss'>
    <div id='error-text'>
        <img src="../../assets/img/sad404.svg" alt="404">
        <span>404 PAGE</span>
        <p class="p-a">
            {{'404.NOT_FOUND' |translate}}</p>
        <p class="p-b"><a href='#' style="color: white"><strong>{{'404.RETURN_TO_HOME' |translate}}</strong></a>
        </p>

    </div>
</div>
