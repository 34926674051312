import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SwGarantiesService} from '../../core/services/site/swGarantie.service';
import {ApplicationService} from '../../core/services/application.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              public applicationService: ApplicationService) { }

  ngOnInit() {
  }

}
