import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationService} from '../../../core/services/application.service';
import {Router} from '@angular/router';
import {Language} from './header.model';
import {LanguageService} from '../../../core/services/language.service';
import {CookieService} from 'ngx-cookie-service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public cookieValue;
    public lang: string;
    public selectedLanguage: Language;

    constructor(private http: HttpClient,
                public languageService: LanguageService,
                public applicationService: ApplicationService,
                public cookieService: CookieService,
                private router: Router) {
    }

    listLang: Language[] = [
        {text: 'English', value: 'en', flag: 'assets/img/flags/en.png'},
        {text: 'Français', value: 'fr', flag: 'assets/img/flags/fr.png'},
        {text: 'العربية', value: 'ar', flag: 'assets/img/flags/ar.png'},
    ];

    ngOnInit(): void {
        this.lang = this.languageService.getLanguage();
        if (this.lang === null) {
            this.lang = 'en';
        }
        this.selectedLanguage = this.listLang.filter(l => l.value === this.lang)[0];
    }

    setLanguage(lang: string) {
        window.location.reload();
        this.selectedLanguage = this.listLang.filter(l => l.value === this.lang)[0];
        this.languageService.setLanguage(lang);
    }
}
