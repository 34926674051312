import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../core/services/language.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {
    public lang: string;

    constructor(public languageService: LanguageService,) {
    }

    ngOnInit(): void {
        this.lang = this.languageService.getLanguage();
    }

}
