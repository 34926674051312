import {Product} from './product.model';

export const PRODUCTS_LIST: Product[] = [
    {
        prdId: btoa('1').toString(),
        titleAr: 'تأمين السيارات',
        titleFr: 'Assurance automobile',
        titleEn: 'Cars Insurance',
        bannerDescriptionAr: 'التأمين على السيارات هو نوع من البوليصة التي توفر الحماية المالية ضد الخسائر الناجمة عن الحوادث أو السرقة أو الأضرار التي تلحق بمركبتك. إنها إلزامية في معظم الولايات .... ',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionFr: 'L\'assurance automobile est un type de police qui offre une protection financière contre les pertes résultant d\'accidents, de vol ou de dommages causés à votre véhicule. C\'est obligatoire dans la plupart des États....',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionEn: 'Car insurance is a type of policy that provides financial protection against losses resulting from accidents, theft, or damage to your vehicle. It is mandatory in most states....',
        titleDetailsAr: '" تأمين السيارة الذي يضع سلامتك أولاً"',
        titleDetailsFr: '" Une assurance automobile qui donne la priorité à votre sécurité "',
        titleDetailsEn: '“Car insurance that puts your safety first”',
        // tslint:disable-next-line:max-line-length
        descriptionAr: 'التأمين على السيارات هو نوع من البوليصة التي توفر الحماية المالية ضد الخسائر الناجمة عن الحوادث أو السرقة أو الأضرار التي تلحق بمركبتك. إنها إلزامية في معظم الولايات ، ويمكن أن تساعد في تغطية تكاليف الإصلاحات أو الفواتير الطبية في حالة وقوع حادث. يمكن أن تختلف سياسات التأمين على السيارات من حيث التغطية والخصومات والأقساط ، لذا من المهم البحث واختيار السياسة المناسبة لاحتياجاتك وميزانيتك. تتضمن بعض العوامل التي يمكن أن تؤثر على تكلفة التأمين على السيارة سجل القيادة والعمر ونوع السيارة والموقع. من المهم مراجعة بوليصة التأمين على سيارتك وتحديثها بانتظام لضمان حصولك على تغطية وحماية كافية.',
        // tslint:disable-next-line:max-line-length
        descriptionFr: 'L\'assurance automobile est un type de police qui offre une protection financière contre les pertes résultant d\'accidents, de vol ou de dommages causés à votre véhicule. C\'est obligatoire dans la plupart des États et peut aider à couvrir les frais de réparations ou les frais médicaux en cas d\'accident. Les polices d\'assurance automobile peuvent varier en termes de couverture, de franchises et de primes, il est donc important de rechercher et de choisir la police adaptée à vos besoins et à votre budget. Certains facteurs qui peuvent affecter le coût de l’assurance automobile comprennent les antécédents de conduite, l’âge, le type de véhicule et l’emplacement. Il est important de revoir et de mettre à jour régulièrement votre police d’assurance automobile pour vous assurer que vous bénéficiez d’une couverture et d’une protection adéquates.',
        // tslint:disable-next-line:max-line-length
        descriptionEn: 'Car insurance is a type of policy that provides financial protection against losses resulting from accidents, theft, or damage to your vehicle. It\'s mandatory in most states, and can help cover the costs of repairs or medical bills in the event of an accident. Car insurance policies can vary in coverage, deductibles, and premiums, so it\'s important to research and choose the right policy for your needs and budget. Some factors that can affect the cost of car insurance include driving history, age, vehicle type, and location. It is important to review and update your car insurance policy regularly to ensure you have adequate coverage and protection.\n',
        imageBanner: 'assets/img/car-insurance.png',
        image: 'assets/img/products/car-dt.jpg',
        imageSous: 'assets/img/souscription/car-souscription.png',
        link: 'https://ameni.b-link.io/ec/#/assurance-auto?returnUrl=%2Fec%2Fproduits'
    },
    {
        prdId: btoa('2').toString(),
        titleAr: 'تأمين المسكن',
        titleFr: 'Assurance habitation',
        titleEn: 'Home insurance',
        bannerDescriptionAr: 'التأمين على المنزل ، المعروف أيضًا باسم تأمين مالك المنزل ، هو نوع من السياسة التي توفر حماية مالية ضد الخسائر أو الأضرار التي تلحق بمنزلك وممتلكاتك الشخصية..... ',
        bannerDescriptionFr: 'L\'assurance habitation, également connue sous le nom d\'assurance habitation, est un type de police qui offre une protection financière contre la perte ou les dommages à votre maison et à vos biens personnels....',
        bannerDescriptionEn: 'Home insurance, also known as homeowner\'s insurance, is a type of policy that provides financial protection against loss or damage to your home and personal property....',
        titleDetailsAr: '"احمِ منزلك وراحة بالك: فوائد التأمين على المنزل "',
        titleDetailsEn: '"Protect your home and peace of mind: Benefits of home insurance "',
        titleDetailsFr: '" Protégez votre maison et votre tranquillité d’esprit : les avantages de l’assurance habitation "',
        // tslint:disable-next-line:max-line-length
        descriptionFr: 'L\'assurance habitation, également connue sous le nom d\'assurance habitation, est un type de police qui offre une protection financière contre la perte ou les dommages causés à votre maison et à vos biens personnels. Elle couvre généralement des événements tels que les incendies, le vol et les catastrophes naturelles, ainsi que la responsabilité si quelqu\'un est blessé sur votre propriété. Les polices d\'assurance habitation peuvent varier en termes de couverture, de franchises et de primes. Il est donc important de rechercher et de choisir la police adaptée à vos besoins et à votre budget. Certains facteurs qui peuvent influer sur le coût de l’assurance habitation comprennent l’âge et l’état de votre maison, la valeur de vos effets personnels et votre emplacement. Il est important de revoir et de mettre à jour régulièrement votre police d’assurance habitation pour vous assurer de bénéficier d’une couverture et d’une protection adéquates.',
        // tslint:disable-next-line:max-line-length
        descriptionAr: 'التأمين على المنزل ، المعروف أيضًا باسم تأمين مالك المنزل ، هو نوع من السياسة التي توفر حماية مالية ضد الخسائر أو الأضرار التي تلحق بمنزلك وممتلكاتك الشخصية. عادةً ما يغطي أحداثًا مثل الحريق والسرقة والكوارث الطبيعية ، بالإضافة إلى المسؤولية في حالة إصابة شخص ما في ممتلكاتك. يمكن أن تختلف سياسات التأمين على المنزل في التغطية والخصومات والأقساط ، لذلك من المهم البحث واختيار السياسة المناسبة لاحتياجاتك وميزانيتك. تشمل بعض العوامل التي يمكن أن تؤثر على تكلفة التأمين على المنزل عمر منزلك وحالته وقيمة ممتلكاتك الشخصية وموقعك. من المهم مراجعة بوليصة التأمين على المنزل وتحديثها بانتظام لضمان حصولك على التغطية والحماية الكافية.',
        // tslint:disable-next-line:max-line-length
        descriptionEn: 'Home insurance, also known as homeowner\'s insurance, is a type of policy that provides financial protection against loss or damage to your home and personal property. It usually covers events such as fire, theft, and natural disasters, as well as liability if someone is injured on your property. Home insurance policies can vary in coverage, deductibles, and premiums, so it\'s important to research and choose the right policy for your needs and budget. Some factors that can affect the cost of home insurance include the age and condition of your home, the value of your personal belongings, and your location. It is important to review and update your home insurance policy regularly to ensure you have adequate coverage and protection.',
        imageBanner: 'assets/img/habitation-insurance.png',
        image: 'assets/img/products/home-dt.jpg',
        imageSous: 'assets/img/souscription/car-souscription.png',
        link: 'https://ameni.b-link.io/ec/#/assurance-habitation?returnUrl=%2Fec%2Fproduits'
    },
    {
        prdId: btoa('3').toString(),
        titleAr: 'الكوارث الطبيعية',
        titleFr: 'Cat-Nat',
        titleEn: 'Natural disasters',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionAr: 'التأمين ضد الكوارث الطبيعية هو نوع من بوليصة التأمين التي توفر الحماية المالية في حالة وقوع كارثة طبيعية مثل إعصار أو زلزال صلاح يمكن أن يساعد في تغطية تكاليف .....',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionFr: 'L\'assurance contre les catastrophes naturelles est un type de police d\'assurance qui offre une protection financière en cas de catastrophe naturelle telle qu\'un ouragan ou un tremblement de terre. Salah peut aider à couvrir les coûts de.....',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionEn: 'Natural disaster insurance is a type of insurance policy that provides financial protection in the event of a natural disaster such as a hurricane or earthquake Salah can help cover the costs of.....',
        titleDetailsAr: '""حماية مستقبلك: أهمية التأمين ضد الكوارث الطبيعية"',
        titleDetailsEn: '“Protecting your future: the importance of natural disaster insurance',
        titleDetailsFr: '" Protéger votre avenir : l’importance de l’assurance catastrophe naturelle "',
        // tslint:disable-next-line:max-line-length
        descriptionFr: 'L\'assurance catastrophe naturelle est un type de police d\'assurance qui offre une protection financière en cas de catastrophe naturelle telle qu\'un ouragan, un tremblement de terre ou une inondation. Il peut aider à couvrir les frais de réparation ou de remplacement des biens endommagés, ainsi que les frais de subsistance supplémentaires si vous ne pouvez pas rester dans votre logement pendant le processus de rétablissement. Les polices d\'assurance contre les catastrophes naturelles peuvent varier en termes de couverture, de franchises et de primes. Il est donc important de rechercher et de choisir la police adaptée à vos besoins et à votre budget. Certains facteurs qui peuvent affecter le coût de l\'assurance contre les catastrophes naturelles comprennent votre emplacement, la probabilité que des catastrophes naturelles se produisent dans votre région et le niveau de couverture que vous choisissez. Il est important de revoir et de mettre à jour régulièrement votre police d’assurance contre les catastrophes naturelles pour garantir que vous bénéficiez d’une couverture et d’une protection adéquates.',
        // tslint:disable-next-line:max-line-length
        descriptionAr: 'التأمين ضد الكوارث الطبيعية هو نوع من بوليصة التأمين التي توفر الحماية المالية في حالة وقوع كارثة طبيعية مثل إعصار أو زلزال أو فيضان . يمكن أن يساعد في تغطية تكاليف إصلاح أو استبدال الممتلكات التالفة ، وكذلك نفقات المعيشة الإضافية إذا كنت غير قادر على البقاء في منزلك أثناء عملية الاسترداد. يمكن أن تختلف سياسات التأمين ضد الكوارث الطبيعية في التغطية والخصومات والأقساط ، لذلك من المهم البحث واختيار السياسة المناسبة لاحتياجاتك وميزانيتك. تتضمن بعض العوامل التي يمكن أن تؤثر على تكلفة التأمين ضد الكوارث الطبيعية موقعك ، واحتمال وقوع كوارث طبيعية في منطقتك ، ومستوى التغطية الذي تختاره. من المهم مراجعة بوليصة التأمين ضد الكوارث الطبيعية وتحديثها بانتظام لضمان حصولك على التغطية والحماية الكافية. ',
        // tslint:disable-next-line:max-line-length
        descriptionEn: 'Natural disaster insurance is a type of insurance policy that provides financial protection in the event of a natural disaster such as a hurricane, earthquake, or flood. It can help cover the costs of repairing or replacing damaged property, as well as additional living expenses if you are unable to remain in your home during the recovery process. Natural disaster insurance policies can vary in coverage, deductibles and premiums, so it\'s important to research and choose the right policy for your needs and budget. Some factors that can affect the cost of natural disaster insurance include your location, the likelihood of natural disasters occurring in your area, and the level of coverage you choose. It is important to review and update your natural disaster insurance policy regularly to ensure you have adequate coverage and protection.',
        imageBanner: 'assets/img/cat-nat-insurance.png',
        image: 'assets/img/products/cat-nat-dt.png',
        imageSous: 'assets/img/souscription/car-souscription.png',
        link: 'https://ameni.b-link.io/ec/#/assurance-cat-nat?returnUrl=%2Fec%2Fproduits'
    },
    {
        prdId: btoa('4').toString(),
        titleAr: 'متعدد المخاطر المهنية',
        titleFr: 'multirisque professionnelle',
        titleEn: 'professional multi-risk',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionAr: 'تعد المخاطر متعددة المهنيين ، والمعروفة أيضًا باسم تأمين المسؤولية المهنية ، نوعًا من بوليصة التأمين التي توفر الحماية المالية للمهنيي ....',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionFr: 'L\'assurance multiprofessionnelle, également appelée assurance responsabilité professionnelle, est un type de police d\'assurance qui offre une protection financière aux professionnels ....',
        // tslint:disable-next-line:max-line-length
        bannerDescriptionEn: 'Multi-professional risk, also known as professional liability insurance, is a type of insurance policy that provides financial protection to professionals if ....',
        titleDetailsAr: '""حماية مستقبلك: أهمية التأمين ضد الكوارث الطبيعية"',
        titleDetailsEn: '“Protecting your future: the importance of natural disaster insurance',
        titleDetailsFr: '" Protéger votre avenir : l’importance de l’assurance catastrophe naturelle "',
        // tslint:disable-next-line:max-line-length
        descriptionFr: 'Le risque multiprofessionnel, également appelé assurance responsabilité professionnelle, est un type de police d\'assurance qui offre une protection financière aux professionnels s\'ils sont poursuivis pour négligence, erreurs ou négligence dans leur travail. Ils sont généralement achetés par des particuliers exerçant des professions telles que des médecins, des avocats, des comptables et des consultants, entre autres. L’assurance risques multiprofessionnelle peut aider à couvrir les frais juridiques, les règlements et les dommages accordés dans le cadre d’un procès. Les polices peuvent varier en termes de couverture, de franchises et de primes, il est donc important de rechercher et de choisir la police adaptée à vos besoins et à votre budget. Les facteurs qui peuvent affecter le coût de l\'assurance risques multiprofessionnels comprennent le type de profession, le niveau de couverture requis et les antécédents de réclamations ou de poursuites d\'un individu. Il est important que les professionnels révisent et mettent régulièrement à jour leur police d’assurance pour s’assurer qu’ils bénéficient d’une couverture et d’une protection adéquates.',
        // tslint:disable-next-line:max-line-length
        descriptionAr: 'تعد المخاطر متعددة المهنيين ، والمعروفة أيضًا باسم تأمين المسؤولية المهنية ، نوعًا من بوليصة التأمين التي توفر الحماية المالية للمهنيين في حالة مقاضاتهم بسبب الإهمال أو الأخطاء أو الإهمال في عملهم. يتم شراؤها عادة من قبل الأفراد في المهن مثل الأطباء والمحامين والمحاسبين والاستشاريين ، من بين آخرين. يمكن أن يساعد التأمين ضد المخاطر متعدد المهنيين في تغطية تكاليف الرسوم القانونية والتسويات والتعويضات الممنوحة في الدعوى القضائية. يمكن أن تختلف السياسات في التغطية والخصومات والأقساط ، لذلك من المهم البحث واختيار السياسة المناسبة لاحتياجاتك وميزانيتك. العوامل التي يمكن أن تؤثر على تكلفة التأمين ضد المخاطر متعددة المهنيين تشمل نوع المهنة ، ومستوى التغطية المطلوبة ، وتاريخ الفرد من المطالبات أو الدعاوى القضائية. من المهم للمهنيين مراجعة بوليصة التأمين الخاصة بهم وتحديثها بانتظام للتأكد من أن لديهم تغطية وحماية كافية.  ',
        // tslint:disable-next-line:max-line-length
        descriptionEn: 'Multi-professional risk, also known as professional liability insurance, is a type of insurance policy that provides financial protection to professionals if they are sued for negligence, errors, or negligence in their work. They are typically purchased by individuals in professions such as doctors, lawyers, accountants, and consultants, among others. Multi-professional risk insurance can help cover the costs of legal fees, settlements, and damages awarded in a lawsuit. Policies can vary in coverage, deductibles, and premiums, so it\'s important to research and choose the right policy for your needs and budget. Factors that can affect the cost of multi-professional risk insurance include the type of profession, the level of coverage required, and an individual\'s history of claims or lawsuits. It is important for professionals to review and update their insurance policy regularly to ensure they have adequate coverage and protection.',
        imageBanner: 'assets/img/mp-insurance.png',
        image: 'assets/img/products/mp-dt.jpg',
        imageSous: 'assets/img/souscription/car-souscription.png',
        link: '#'
    },
];


