import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SwBlogsService} from '../../core/services/site/swBlogs.service';
import {ActivatedRoute, Params, Router} from '../../../../node_modules/@angular/router';
import {ApplicationService} from '../../core/services/application.service';
import {SwBlogs} from '../../core/models/site-entity/sw-blogs';
import {LanguageService} from '../../core/services/language.service';


@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    providers: [ApplicationService]
})
export class BlogComponent implements OnInit, OnChanges {
    public lang: string;

    selectedBlog: SwBlogs;
    blogPath: string;

    constructor(private http: HttpClient,
                public applicationService: ApplicationService,
                private router: Router,
                private route: ActivatedRoute,
                public languageService: LanguageService,
                private swBlogsService: SwBlogsService) {
    }

    ngOnInit(): void {
        this.lang = this.languageService.getLanguage();
        this.selectedBlog = new SwBlogs();
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.blogPath = params.path;
                    console.log(this.blogPath);
                    this.findBlogByPath(this.blogPath);
                }
            );
    }

    private findBlogByPath(path: string) {
        const params = new HttpParams()
            .set('path', path);

        this.swBlogsService.findBlogByPath(params).subscribe(
            data => {
                this.selectedBlog = data;
                console.log('blog ttitle' + this.selectedBlog.sblTitreLoc);
            },
            error => console.log(error)
        );

    }

    ngOnChanges(changes: SimpleChanges): void {
    }

}
