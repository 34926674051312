import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SwBlogsService} from '../../core/services/site/swBlogs.service';
import {ApplicationService} from '../../core/services/application.service';
import {Router} from '@angular/router';
import {SwBlogs} from '../../core/models/site-entity/sw-blogs';
import {LanguageService} from '../../core/services/language.service';

@Component({
    selector: 'app-blogs',
    templateUrl: './blogs.component.html',
    styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
    public lang: string;

    constructor(private http: HttpClient,
                private swBlogsService: SwBlogsService,
                public languageService: LanguageService,
                public applicationService: ApplicationService,
                private router: Router) {
    }

    listAllBlogs: SwBlogs[];

    ngOnInit(): void {
        this.lang = this.languageService.getLanguage();
        this.findAllBlogs();
    }

    public findAllBlogs() {

        this.swBlogsService.findAllBlogs().subscribe(
            data => {
                this.listAllBlogs = data;
                console.log(this.listAllBlogs);

            }, error => {
                if (error.status === '403') {
                    this.router.navigate(['/auth/login']);
                }
            },
        );

    }


}
