

export class SwBlogs {
    sblId: string;
    sblTitreLoc: string;
    sblTitreFrn: string;
    sblDescLoc: string;
    sblDescFrn: string;
    sblVue: number;
    sblAdresse: string;
    sblPath: string;
    sblDureeFlag: number;
    sblInactiveDate: Date;
    sblInactivePour: string;
    sblCreerPar: string;
    sblCreationDate: Date;
    sblModifierPar: string;
    sblModificationDate: Date;
    sblTitreArb: string;
    sblTitreAmz: string;
    sblDescArb: string;
    sblDescAmz: string;
    sblPathImage: string;
    sblImage: any;
}

