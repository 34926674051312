<div class="one-among-area two-among-area pt-5  pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">{{'CONTACT_FORM.TITLE' | translate}}</span>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="../../../assets/img/menue/nos-agences.jpg" alt="Among">
                        <div class="among-icon" (click)="this.applicationService.goToLink('#/nos-agences')">
                            <i class='bx bxs-map'></i>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/menue/contact.jpg" alt="Among">
                        <div class="among-icon" (click)="this.applicationService.goToLink('#contact-form')">
                            <i class='bx bx-phone-call'></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/menue/souscription.jpg" alt="Among">
                        <div class="among-icon" (click)="this.applicationService.goToLink('#/')">
                            <i class='bx bxs-file'></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
