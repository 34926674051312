import {SwCommentaires} from './sw-commentaires';
import {SwGaranties} from './sw-garantie';
import {SwPromotions} from './sw-promotions';


export class SwProduits {
    sprId: string;
    sprType: string;
    sprNomLoc: string;
    sprNomFrn: string;
    sprAbrvLoc: string;
    sprAbrvFrn: string;
    sprDescLoc: string;
    sprDescFrn: string;
    sprPath: string;
    sprBannerOrdre: number;
    sprInactiveDate: Date;
    sprInactivePour: string;
    sprCreerPar: string;
    sprCreationDate: Date;
    sprModifierPar: string;
    sprModificationDate: Date;
    sprNomArb: string;
    sprAbrvArb: string;
    sprDescArb: string;
    sprImageMin: any;
    sprImageLarge: any;
    sprIcon: any;
    swGarantiesList: SwGaranties[];
    swCommentairesList: SwCommentaires[];
    swPromotionsList: SwPromotions[];

}

