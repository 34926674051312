import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '../../../../node_modules/@angular/router';
import {ApplicationService} from '../../core/services/application.service';
import {Meta, Title} from '@angular/platform-browser';
import {LanguageService} from '../../core/services/language.service';


@Component({
    selector: 'app-our-agencies',
    templateUrl: './our-agencies.component.html',
    styleUrls: ['./our-agencies.component.scss'],
    providers: [ApplicationService]
})
export class OurAgenciesComponent implements OnInit, AfterViewInit {

    public lang: string;

    lat = 36.743883934354415;
    lng = 2.9471590207091167;
    agnZoom = 10;
    public markers: any[];
    public center: { lat: number; lng: number };
    public zoom: number;
    public markerUrl = 'assets/img/pin.png';

    constructor(private http: HttpClient,
                private titleService: Title,
                private router: Router,
                private route: ActivatedRoute,
                public languageService: LanguageService,
                private metaTagService: Meta,
                public applicationService: ApplicationService) {

        this.metaTagService.updateTag(
            {name: 'description', content: this.route.snapshot.data.description}
        );
        this.titleService.setTitle(this.route.snapshot.data.title);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.markers = [];
        this.zoom = 12;
    }


    ngAfterViewInit() {

    }

    ngOnInit() {
        this.lang = this.languageService.getLanguage();
        this.center = {
            lat: this.lat,
            lng: this.lng,
        };
    }


}
