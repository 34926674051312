<div id="home" class="better-home-area "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="better-home-content">
                    <!--<span class="sub-title">Welcome to Levi</span>-->
                    <h1 i18n="title bunner">{{'LANDING.B_LINK_AMENI' | translate}}</h1>
                    <h2 style="color: #54535d" i18n="secound title bunner">{{'LANDING.EMPOWER_FUTURE' |translate}}</h2>
                    <p i18n="sub title for banner">{{'LANDING.SECURE_FUTURE' |translate}}</p>
                    <p i18n="secound sub-title for bunner">{{'LANDING.BEST_COVERAGE' |translate}}</p>

                    <ul class="better-home-btn">
                        <li>
                            <a (click)="applicationService.goToLink('https://ameni.b-link.io/ec/#/ec/produits')"
                               class="main-default-btn cursor-pointer">{{'LANDING.GET_INSTANT_QUOTE_NOW' |translate}}</a>
                        </li>
                        <li>
                            <a href="#/about" class="main-optional-btn">{{'LANDING.ABOUT_US' |translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="better-home-image">
                    <img src="assets/img/better-home.png" alt="image">

                    <a href="https://www.youtube.com/embed/mlDe6Zd-ZSs" class="video-btn popup-youtube"><i
                        class="bx bx-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="about-our-company" class="about-style-area pb-2 "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image-wrap">
                    <img src="assets/img/more-home/about/about-2.png" alt="image">

                    <div class="certified-image">
                        <img src="assets/img/about-logo.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 ">
                <div class="about-style-wrap-content">
                    <span class="sub-title">{{'LANDING.ABOUT_US' |translate}} </span>
                    <h3>{{'LANDING.WHY_CHOOSE_AMENI' |translate}}</h3>
                    <p class="bold">{{'LANDING.AMENI_OPTIONS' |translate}}</p>

                    <div class="about-list-tab">
                        <ul class="nav nav-tabs" id="myTab1" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="about-1-tab" data-bs-toggle="tab" href="#about-1"
                                   role="tab" aria-controls="about-1">{{'LANDING.OUR_VISION' |translate}}</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="about-2-tab" data-bs-toggle="tab" href="#about-2" role="tab"
                                   aria-controls="about-2">{{'LANDING.OUR_MISSION' |translate}}</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="about-1" role="tabpanel">
                                <div class="content">
                                    <p>{{'LANDING.OUR_MISSION_TITLE' | translate}}</p>

                                    <ul class="list">
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i> تعزيز القيم التعاونية</li>-->
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i>توفير تغطية تأمينية إسلامية</li>-->
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i>تعزيز تنمية المجتمع</li>-->
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="about-2" role="tabpanel">
                                <div class="content">
                                    <p>{{'LANDING.OUR_VISION_TITLE' | translate}}</p>

                                    <ul class="list">
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i> تقديم حلول التأمين الأخلاقية</li>-->
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i>تعزيز الإدماج المالي</li>-->
                                        <!--                                        <li><i class='bx bx-chevrons-left'></i> تعزيز التنمية المستدامة</li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="information-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center"
             [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-time'></i>
                    </div>
                    <p>
                        <span>{{'LANDING.OPERATING_DAYS' |translate}}</span>
                        <span>{{'LANDING.OPERATING_HOURS' |translate}}</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bxs-contact'></i>
                    </div>
                    <p>
                        <span>{{'LANDING.CONTACT_US' |translate}}</span>
                        <span><a href="mailto:contact@b-link.io">{{'LANDING.CONTACT_EMAIL' |translate}}</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <p>
                        <span>{{'LANDING.CONTACT_AVAILABILITY' |translate}}</span>
                        <span><a href="tel:4234543123678">{{'LANDING.CONTACT_PHONE' |translate}}</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-world'></i>
                    </div>
                    <p>
                        <span>{{'LANDING.ADDRESS' |translate}} </span>
                        <span>{{'LANDING.ADDRESS_VALUE' |translate}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="find-own-loan-area pt-70 pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">{{'LANDING.TYPES_OF_OFFERS' |translate}}</span>
            <h2>{{'LANDING.TYPES_OF_OFFERS' |translate}}</h2>
            <p class="bold pt-3">
                {{'LANDING.INVEST_IN_YOUR_FUTURE' |translate}}
            </p>
        </div>

        <div class="find-own-loan-list-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1"
                       role="tab" aria-controls="find-own-loan-1">{{'LANDING.B_LINK_AMENI' | translate}}</a>
                </li>

            </ul>

            <div class="tab-content" id="typeProducts">
                <div class="tab-pane fade show active " id="find-own-loan-1" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="p-3 find-own-loan-card "
                             [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'"
                             *ngFor="let prd of listProducts ;let i=index; first as isFirst">
                            <div class="loan-image">
                                <img src="{{prd.imageBanner}}" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag"
                                      (click)="applicationService.goToLink(prd.link)">{{'SUBSCRIBE_NOW' | translate}}</span>
                                <h3>{{languageService.getLanguage() === 'en' ? prd.titleEn : languageService.getLanguage() === 'fr' ? prd.titleFr : languageService.getLanguage() === 'ar' ? prd.titleAr : prd.titleEn}}</h3>
                                <p>{{languageService.getLanguage() === 'en' ? prd.bannerDescriptionEn : languageService.getLanguage() === 'fr' ? prd.bannerDescriptionFr : languageService.getLanguage() === 'ar' ?
                                    prd.bannerDescriptionAr : prd.bannerDescriptionEn}}</p>
                                <a class="cmn-banner-btn "
                                   [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'"
                                   (click)="applicationService.goToDetails('products',prd.prdId)">{{'LEARN_MORE' | translate}}
                                    <i
                                        class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="faq-style-area-with-full-width  "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-style-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-style-accordion">
                    <span class="sub-title">{{'LANDING.FAQ.TEXT' | translate}}</span>
                    <h3>{{'LANDING.NEED_HELP' | translate}}</h3>

                    <div class="accordion" id="FaqAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {{'LANDING.FAQ.QUESTION_1.QUESTION' | translate}}
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                 data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p> {{'LANDING.FAQ.QUESTION_1.ANSWER' | translate}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {{'LANDING.FAQ.QUESTION_2.QUESTION' | translate}}
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p> {{'LANDING.FAQ.QUESTION_2.ANSWER' | translate}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {{'LANDING.FAQ.QUESTION_3.QUESTION' | translate}}
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p> {{'LANDING.FAQ.QUESTION_3.ANSWER' | translate}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {{'LANDING.FAQ.QUESTION_4.QUESTION' | translate}}
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p> {{'LANDING.FAQ.QUESTION_4.ANSWER' | translate}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonials-style-area-with-bg bg-style-two ptb-100 " >
    <div class="container">
        <div class="testimonials-style-content" [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
            <span class="sub-title">{{'LANDING.CONTACT_AGENCIES'|translate}}</span>
            <h3>{{'LANDING.AGENCY_DISCOVERY'|translate}}</h3>
            <div class="testimonials-style-slider owl-carousel owl-theme">
                <div class="testimonials-style-card">
                    <p>{{'LANDING.OUR_NETWORK'|translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-contact-form id="contact-form"></app-contact-form>
<div id="blog" class="blog-style-area pt-100 pb-100 "
     [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">{{'NEWS_AND_BLOG.TEXT'|translate}}</span>
            <h2>{{'NEWS_AND_BLOG.NEWS_AND_FEATURED_STATS'|translate}}</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image" (click)="applicationService.goToDetails('blogs',newestBlog.sblPath)">
                        <a><img src="assets/img/blog-1.jpg" alt="image"></a>
                        <div class="tag">{{newestBlog.sblTitreFrn}}</div>
                    </div>
                    <div class="blog-content">
                        <ul class="post-meta d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/admin.png" class="rounded-circle" alt="image">
                                    <span>{{'SITE_NAME' | translate}}</span>
                                </div>
                            </li>

                            <li>
                                <i class='bx bxs-calendar'></i> {{newestBlog.sblCreationDate | date}}
                            </li>

                            <li>
                                <i class='bx bx-map'></i> {{newestBlog.sblAdresse}}
                            </li>
                        </ul>
                        <h3>
                            <a (click)="applicationService.goToDetails('blogs',newestBlog.sblPath)">{{newestBlog.sblTitreFrn}}</a>
                        </h3>
                        <div class="text-lenght" [innerHTML]="newestBlog.sblDescFrn"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="blog-style-right-card">
                    <div class="card-box"
                         *ngFor="let blog of listAllBlogs.slice(1, listAllBlogs.length) ; index as i; first as isFirst">
                        <div class="blog-content">
                            <div class="tag">{{blog.sblTitreFrn}}</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> {{blog.sblCreationDate | date}}
                                </li>

                                <li>
                                    <i class='bx bx-map'></i> {{blog.sblAdresse}}
                                </li>
                            </ul>
                            <h3>
                                <a href="#/blogs/{{blog.sblPath}}">{{blog.sblTitreFrn}}</a>
                            </h3>
                            <div class="text-lenght" [innerHTML]="blog.sblDescFrn"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="one-subscribe-area two-subscribe-area ptb-100 "
         [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'">
    <div class="container">
        <div class="subscribe-wrap">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <h2>{{'NEWS_AND_BLOG.BE_THE_FIRST_TO_KNOW'|translate}}</h2>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form" data-bs-toggle="validator">
                        <input type="email" class="form-control "
                               [ngClass]="languageService.getLanguage()==='ar'?'direction-rtl' : 'direction-ltr'"
                               placeholder="Enter your email"
                               name="EMAIL" required
                               autocomplete="off">
                        <button class="btn subscribe-btn"
                                type="submit">{{'NEWS_AND_BLOG.SUBSCRIBE_NOW'|translate}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

