import {SwProduits} from './sw-produits';

export class SwPromotions {
    swpTitreLoc: string;
    swpTitreFrn: string;
    swpDescLoc: string;
    swpDescFrn: string;
    swpBadge: string;
    swpCreerPar: string;
    swpCreationDate: Date;
    swpModifierPar: string;
    swpModificationDate: Date;
    swpIcon: string;
    swpActiveFlag: string;
    swProduits: SwProduits;
    swpId: string;
}

