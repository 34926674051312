import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {BlogsComponent} from './blogs/blogs.component';
import {BlogComponent} from './blog/blog.component';
import {OurAgenciesComponent} from './our-agencies/our-agencies.component';
import {ContactComponent} from './contact/contact.component';
import {ProductComponent} from './product/product.component';
import {AboutComponent} from './about/about.component';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '', component: LandingComponent, pathMatch: 'full',
        data: {
            title: 'Ameni',
            description: 'lorem uipsum"',
        }
    },
    {
        path: 'blogs', component: BlogsComponent,
        data: {
            title: 'blogs',
            description: 'lorem uipsum"',
        }
    },
    {
        path: 'blogs/:path', component: BlogComponent, pathMatch: 'full',
        data: {
            title: 'blogs',
            description: 'lorem upsom'
        }

    },

    {
        path: 'nos-agences', component: OurAgenciesComponent,
        data: {
            title: 'Ameni ',
            description: '...'
        }
    },
    {
        path: 'contact', component: ContactComponent,
        data: {
            title: 'Ameni ',
            description: '...'
        }
    },

    {
        path: 'products', component: ProductComponent,
        data: {
            title: 'Ameni ',
            description: '...'
        }
    },
    {
        path: 'products/:id', component: ProductComponent, pathMatch: 'full',
        data: {
            title: 'Ameni ',
            description: '...'
        }

    },

    {
        path: 'about', component: AboutComponent,
        data: {
            title: 'Ameni ',
            description: '...'
        }
    },


    {
        path: '**', pathMatch: 'full',
        component: PageNotFoundComponent
    },


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {
}
