import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export class Message {
    constructor(public author: string, public content: string) {}
}
@Injectable()
export class ChatService {
    constructor() {}
    conversation = new Subject<Message[]>();
    messageMap = {
        مرحبا: 'مرحبا بك كيف حالك',
        'التأمين التكافلي' : 'نظام تأمين قائم على مبادئ الشريعة الإسلامية',
        'من أنت': 'اسمي محمد ، برنامج الدردشة الآلي',
        'ما هو دورك': 'مجرد دليل للمستخدم',
        التسعير : 'يجب عليك استشارة منطقة العملاء للحصول على مزيد من التفاصيل',
        defaultmsg: 'لا استطيع فهم النص الخاص بك. هل يمكنك التكرار من فضلك'
    };
    getBotAnswer(msg: string) {
        const userMessage = new Message('user', msg);
        this.conversation.next([userMessage]);
        const botMessage = new Message('bot', this.getBotMessage(msg));
        setTimeout(() => {
            this.conversation.next([botMessage]);
        }, 1500);
    }
    getBotMessage(question: string) {
        const answer = this.messageMap[question];
        return answer || this.messageMap.defaultmsg;
    }
}
